import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from 'styles';
import Card from 'react-app/components/Card';
import Avatar from 'react-app/components/Avatar';
import Link from 'components/Link';
import Img from 'react-app/components/Img';
import { useDialog } from 'react-app/components/DialogProvider';
import CardContent from 'react-app/components/CardContent';
import CardActionArea from 'components/CardActionArea';
import Typography from '@mui/material/Typography';
import MovieIcon from '@mui/icons-material/Movie';
import LinkIcon from '@mui/icons-material/Link';
import ConfigIcon from '@mui/icons-material/Settings';
import DocsIcon from '@mui/icons-material/Description';
import CatalogIcon from 'react-app/icons/Catalog';
import PercentCircleIcon from 'react-app/icons/PercentCircle';
import EventIcon from '@mui/icons-material/EventAvailable';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';
import OpenInDialonIcon from '@mui/icons-material/OpenInBrowser';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpenInPageIcon from '@mui/icons-material/ArrowForward';
import ServiceIcon from 'react-app/icons/Handshake';
import * as images from 'config/images';

const sizeBase = 80;
const imgSize = sizeBase + 32;

const icons = {
	video: MovieIcon,
	link: LinkIcon,
	config: ConfigIcon,
	docs: DocsIcon,
	catalog: CatalogIcon,
	sale: PercentCircleIcon,
	event: EventIcon,
	service: ServiceIcon,
	pinterest: PinterestIcon,
	youtube: YouTubeIcon
};

const useClasses = createClasses((theme) => ({
	root: {
		border: 'none',
		flexDirection: 'row',
		textAlign: 'left',
		maxWidth: 360
	},
	cardActionArea: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%'
	},
	media: {
		flex: `0 0 ${sizeBase}px`,
		height: sizeBase,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		...theme.mixins.gutters(['marginY', 'marginLeft']),
	},
	content: {
		whiteSpace: 'pre-line',
		...theme.mixins.gutters()
	},
	avatar: {
		width: theme.spacing(10),
		height: theme.spacing(10),
	},
	avatarIcon: {
		'&&': {
			fontSize: '3rem'
		}
	},
	avatarImg: {
		display: 'flex',
		alignItems: 'center',
		'&& > img': {
			objectPosition: 'center',
			maxWidth: theme.spacing(10),
			maxHeight: theme.spacing(10),
			minWidth: theme.spacing(8),
			minHeight: theme.spacing(8),
			margin: 'auto',
			'&:before': {
				backgroundSize: '100% 100%'
			}
		}
	},
	title: {
		...theme.mixins.lineClamp,
		fontWeight: 700,
		marginBottom: theme.spacing(.5),
		fontSize: theme.typography.pxToRem(14),
		lineHeight: theme.typography.pxToRem(18),
		paddingRight: theme.spacing(2.5)
	},
	subtitle: {
		...theme.mixins.lineClamp,
		marginTop: theme.spacing(.5),
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: theme.typography.pxToRem(13),
		lineHeight: theme.typography.pxToRem(15),
	},
	description: {
		fontSize: theme.typography.pxToRem(12),
		lineHeight: theme.typography.pxToRem(14),
		marginTop: theme.spacing(1)
	},
	icon: {
		color: theme.config.palette.text.primary,
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
		opacity: .5
	},
	logo: {
		// backgroundColor: 'transparent'
	},
	imgRoot: {
		margin: theme.spacing(1)
	},
	image: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minWidth: imgSize,
		maxWidth: imgSize,
		minHeight: imgSize,
		maxHeight: '100%',
		objectFit: 'cover',
		objectPosition: 'center',
		backgroundColor: theme.config.palette.background.placeholder
	},
	type: {
		width: '100%',
		height: 22,
		textAlign: 'center',
		fontSize: theme.typography.pxToRem(11),
		lineHeight: theme.typography.pxToRem(15),
		padding: theme.spacing(.5, 1),
		position: 'absolute',
		bottom: 0,
		fontWeight: theme.config.darkMode ? 500 : 600,
		textTransform: 'uppercase',
		backgroundColor: `rgba(0, 0, 0, ${theme.config.darkMode ? .18 : .06})`
	}
}), {
	name: 'RelatedCard'
});

function RelatedCard(props) {
	const {
		id,
		classes: classesProp,
		className,
		type = 'docs',
		icon,
		pageId,
		logoId,
		imageId,
		imageSrc,
		noAvatar,
		href: hrefProp,
		externalLink,
		onClick,
		dialogTitle,
		variant,
		title,
		subtitle,
		description,
		userType,
		...rest
	} = props;

	const classes = useClasses(props);

	const Icon = icons[icon || type] || icons.docs;
	const href = externalLink || hrefProp || '';

	const {openDialog} = useDialog();

	if (!title && !subtitle && !description) return null;

	const image = images[imageId || `related_${id}_card`] || images[id] || images[logoId];

	return (
		<Card
			{...rest}
			className={classes.root}
		>
			<CardActionArea
				component={!onClick && href ? Link : undefined}
				className={classes.cardActionArea}
				title={dialogTitle || title}
				href={!onClick ? href : null}
				data-src={href}
				id={pageId}
				onClick={onClick === 'openPageDialog' ? openDialog : typeof onClick === 'function' ? onClick : null}
			>
				{onClick ? (
					<OpenInDialonIcon className={classes.icon}/>
				) : href.match(/^https?:\/\//) ? (
					<OpenInNewIcon className={classes.icon}/>
				) : href ? (
					<OpenInPageIcon style={{transform: 'rotate(-45deg)'}} className={classes.icon}/>
				) : (
					null
				)}

				{!noAvatar ? (
					<div className={classes.media}>
						<Avatar
							variant={variant}
							className={clsx(
								classes.avatar,
								image && classes.logo
							)}
							ImgProps={{
								width: imgSize,
								height: imgSize,
								classes: {
									root: classes.avatarImg
								}
							}}
							alt={title}
							src={image?.src}
						>
							{!image && (
								<Icon className={classes.avatarIcon}/>
							)}
						</Avatar>
					</div>
				) : (
					<Img
						{...image}
						alt={title}
						className={classes.imgRoot}
						classes={{
							common: classes.image
						}}
					/>
				)}

				<CardContent className={classes.content}>
					{title && (
						<Typography
							className={classes.title}
							variant="h6"
							component="h2"
						>
							{title}
						</Typography>
					)}

					{subtitle && (
						<Typography
							className={classes.subtitle}
							variant="subtitle1"
							component="h3"
						>
							{subtitle}
						</Typography>
					)}

					{description && (
						<Typography className={classes.description}>
							{description}
						</Typography>
					)}
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

RelatedCard.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	pageId: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	description: PropTypes.string,
	href: PropTypes.string,
	externalLink: PropTypes.string,
	onClick: PropTypes.string,
	dialogTitle: PropTypes.string,
	type: PropTypes.string,
	icon: PropTypes.string,
	userType: PropTypes.oneOf(['retail', 'wholesale']),
	noAvatar: PropTypes.bool
};

export default React.memo(RelatedCard);
